<app-header></app-header>

<div
  class="container-fluid"
  *ngIf="getDetailsService().getProducer() && getDetailsService().getProducer().getTransactions()"
>
  <div class="row header">
    <agm-map
    [latitude]="getDetailsService().getWetmillLatitude()"
    [longitude]="getDetailsService().getWetmillLongitude()"
      [zoom]="6"
      [disableDefaultUI]="true"
      id="headerMap"
    >
      <agm-overlay
        [latitude]="getDetailsService().getWetmillLatitude()"
        [longitude]="getDetailsService().getWetmillLongitude()"
      >
        <div>
          <img
            src="/assets/global/images/mapimages/beanIcon.png"
            class="headerIcon"
          />
          <img
            src="/assets/global/images/mapimages/Picture_coffee.png"
            class="headerFarmer pink-background"
          />
        </div>
      </agm-overlay>

      <agm-overlay
        [latitude]="getDetailsService().getRoasterLatitude()"
        [longitude]="getDetailsService().getRoasterLongitude()"
      >
        <div>
          <img
            src="/assets/global/images/mapimages/waterIcon.png"
            class="headerIcon"
          />
          <img
            src="/assets/global/images/mapimages/Picture_machine.png"
            class="headerFarmer pink-background"
          />
        </div>
      </agm-overlay>
    </agm-map>
  </div>

  <div class="row headertext">
    <div class="col-lg-12">
      <div class="pd-title-1">
        <span class="text-black d-lg-inline-block position-relative">
          {{'pd-title-1' | translate}}
          <span class="text-pink"> {{'pd-title-2' | translate}}</span>
          {{'pd-title-3' | translate}}
        </span>
      </div>

      <div class="pd-paragraph-1">
        <span> {{'pd-paragraph-1' | translate}} </span>
      </div>
    </div>
  </div>

  <div class="row contentrow1">
    <div class="col-lg-6">
      <div>
        <span class="text-center"> {{'pd-paragraph-2' | translate}} </span>

        <span class="text-pink"> {{'pd-paragraph-3' | translate}} </span>
      </div>

      <div>
        <span class="text-black"> {{'pd-paragraph-4' | translate}} </span>
      </div>

      <div class="text-center">
        <button routerLink="/brand" class="btn">
          {{'pd-button-1' | translate}}
        </button>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div>
          <!-- Background -->
        </div>
      </div>

      <div class="row">
        <span class="text-black pd-title-3">
          {{'pd-title-4' | translate}}
        </span>
      </div>

      <div class="row">
        <span class="text-black"> {{'pd-paragraph-5' | translate}} </span>
      </div>

      <div class="row">
        <ul class="ul">
          <img
            class="img-fluid"
            src="/assets/global/images/farmers/timercoffee.png"
            alt="timercoffee"
          />
          <span class="text-grey">
            {{'pd-storyinfo-1' | translate: {PRODUCER_AMOUNT: getWashed() } }}
          </span>
        </ul>
      </div>

      <div class="row">
        <button data-toggle="collapse" href="#blockchain2" class="btn">
          {{'pd-button-2' | translate}}
        </button>
      </div>

      <div class="row">
        <span class="pd-storyinfo-2 font-weight-light">
          <a routerLink="/transactions">Alle transacties</a> | 
          {{'pd-storyinfo-2' | translate}}
        </span>
      </div>

      <div class="row table-responsive blockchain-proof collapse" id="blockchain2">
        <table class="table table-striped blockchain-proof table-responsive">
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Kilo</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of getWetmills()">
              <td>{{i.sender}}</td>
              <td>{{i.receiver}}</td>
              <td>{{i.getQuantity() > 0 ? i.getQuantity() + 'kg': '-' }}</td>
              <td>{{i.getPrice() > 0 ? '€' + i.getPrice(): '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row contentrow2">
    <div class="col-lg-6">
      <div class="row">
        <agm-map
          [latitude]="getDetailsService().getRoasterLatitude()"
          [longitude]="getDetailsService().getRoasterLongitude()"
          [zoom]="6"
          [disableDefaultUI]="true"
        >
          <agm-overlay
            [latitude]="getDetailsService().getRoasterLatitude()"
            [longitude]="getDetailsService().getRoasterLongitude()"
          >
            <div>
              <img
                src="/assets/global/images/mapimages/beanIcon.png"
                class="headerIcon"
              />
              <img
                src="/assets/global/images/mapimages/beans.png"
                class="headerFarmer pink-background"
              />
            </div>
          </agm-overlay>
        </agm-map>
      </div>

      <div class="row">
        <span class="text-black pd-title-4">
          {{'pd-title-5' | translate}}
        </span>
      </div>

      <div class="row">
        <span class="text-black"> {{'pd-paragraph-6' | translate}} </span>
      </div>

      <div class="row">
        <ul class="ul">
          <img
            class="img-fluid"
            src="/assets/global/images/farmers/timercoffee.png"
            alt="farmers"
          />
          <span class="text-grey"> {{'pd-storyinfo-3' | translate: {PRODUCER_AMOUNT: getBurnAmount() } }} </span>
        </ul>

        <ul class="ul">
          <img
            class="img-fluid"
            src="/assets/global/images/farmers/coinscoffee.png"
            alt="farmers"
          />
          <span class="text-grey"> {{'pd-storyinfo-4' | translate: {PRODUCER_FEE: getBurnFee() } }} </span>
        </ul>
      </div>

      <div class="row">
        <button data-toggle="collapse" href="#blockchain3" class="btn">
          {{'pd-button-3' | translate}}
        </button>
      </div>

      <div class="row">
        <span class="pd-storyinfo-5 font-weight-light">
          <a routerLink="/transactions">Alle transacties</a> | 
          {{'pd-storyinfo-5' | translate}}
        </span>
      </div>

      <div class="row table-responsive blockchain-proof collapse" id="blockchain3">
        <table class="table table-striped blockchain-proof table-responsive">
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Kilo</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of getRoasters()">
              <td>{{i.sender}}</td>
              <td>{{i.receiver}}</td>
              <td>{{i.getQuantity() > 0 ? i.getQuantity() + 'kg': '-' }}</td>
              <td>{{i.getPrice() > 0 ? '€' + i.getPrice(): '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <!-- Background -->
      </div>
    </div>
  </div>

  <div class="row footer justify-content-md-center">
    <div class="col col-lg-2">
      <span class="pd-title-6 text-white" style="max-width: 500px">
        {{'pd-title-6' | translate}}
      </span>
    </div>

    <div class="col-lg-3">
      <span class="text-white"> {{'pd-paragraph-7' | translate}} </span>
    </div>

    <div class="col col-lg-2 text-center d-none d-lg-block">
      <img
        src="/assets/global/images/landingpage/lp-footerlogo.png"
        class="img-fluid"
        alt="footerlogo"
      />
    </div>
  </div>

  <div class="row spacing d-lg-none back-grey"></div>
</div>

<app-footer></app-footer>
