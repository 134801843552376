import FarmerInterface from '../interfaces/Farmer';
import Transaction from './Transaction';

export default class Farmer {
  private id: number;
  private recordId: number;

  private farmerId: string;

  private tagsId: number;
  private tagsCount: number;

  private firstNames: string;
  private lastName: string;
  private fullName: string; // slechte data

  private createdByName: string;
  private createdTimestamp: string;
  // private creationDate: string; //dubbele data

  private language: string;
  private mobileNumber: string;
  private sex: string;
  private photo: string;

  private modifiedByName: string;
  private modifiedTimestamp: string;
  // private lastModifiedDate: string; // dubbele data

  private sectionsCount: number;
  private sectionsId: number;
  private sectionsNames: string;

  private villageCountry: string;
  private villageId: number;
  private villageFourthLevelZone: string;
  private villageSecondLevelZone: string;
  private villageThirdLevelZone: string;
  private village: string;

  private latitude: number;
  private longitude: number;

  private transactions: Array<Transaction>;

  public constructor(data: FarmerInterface, transactions: Array<Transaction> = []) {
    
    console.log('New farmer: ', {
      data: data,
      transactions: transactions
    });

    this.setId(data.id);
    this.setRecordId(data.recordId);

    this.setFarmerId(data.farmerId);

    this.setTagsId(+data.tagsId);
    this.setTagsCount(+data.tagsCount);

    this.setFirstNames(data.firstname);
    this.setLastName(data.lastname);
    this.setFullName(data.fullname || data.farmername);

    this.setCreatedByName(data.createdByName);
    this.setCreatedTimestamp(data.createdtimestamp);

    this.setLanguage(data.language);
    this.setMobileNumber(data.mobileNumber);
    this.setSex(data.sex);
    this.setPhoto(data.photo || "/assets/global/images/default-user-image.png");

    this.setMofifiedByName(data.modifiedByName);
    this.setModifiedTimestamp(data.modifiedtimestamp);

    this.setSectionsId(+data.sectionsId);
    this.setsectionsCount(+data.sectionsCount);
    this.setSectionsNames(data.sectionsNames);

    this.setVillageCountry(data.villageCountry);
    this.setVillageId(+data.villageId);
    this.setVillageSecondLevelZone(data.villageSecondLevelZone);
    this.setVillageThirdLevelZone(data.villageThirdLevelZone);
    this.setVillageFourthLevelZone(data.villageFourthLevelZone);
    this.setVillage(data.villageVillage);

    this.setTransactions(transactions);
    
    this.setLatitude(0);
    this.setLongitude(0);
  }

  public getFullName(): string {
    if (this.getFirstNames() || this.getLastName())
      return `${this.getFirstNames()} ${this.getLastName()}`;
    return this.fullName;
  }

  public setFullName(fullName: string): void {
    this.fullName = fullName;
  }

  public getTransaction(transactionId: string) {
    return this.getTransactions().find((transaction: Transaction) => {
      return transaction.getTxid() == transactionId;
    });
  }

  public getQuantity(): string {
    return this.getTransactions() ? this.getTransactions().reduce((total: any, transaction: Transaction) => {
      return total + transaction.getQuantity();
    }, 0) : "0";
  }

  public getSum(): string {
    return (parseInt(this.getEarned()) / 2).toFixed(2).toString();
  }

  public getEarned(): string {
    return this.getTransactions() ? this.getTransactions().reduce((total: any, transaction: Transaction) => {
      return total + transaction.getPrice() / 32;
    }, 0).toFixed(2) : "0";
  }

  /**
   * Getters & setters
   */

  public getId(): number {
    return this.id;
  }

  public setId(id: number): void {
    this.id = id;
  }

  public getRecordId(): number {
    return this.recordId;
  }

  public setRecordId(recordId: number): void {
    this.recordId = recordId;
  }

  public getFarmerId(): string {
    return this.farmerId;
  }

  public setFarmerId(farmerId: string): void {
    this.farmerId = farmerId;
  }

  public getTagsId(): number {
    return this.tagsId;
  }

  public setTagsId(tagsId: number): void {
    this.tagsId = tagsId;
  }

  public getTagsCount(): number {
    return this.tagsCount;
  }

  public setTagsCount(tagsCount: number): void {
    this.tagsCount = tagsCount;
  }

  public getFirstNames(): string {
    return this.firstNames;
  }

  public setFirstNames(firstNames: string): void {
    this.firstNames = firstNames;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public setLastName(lastName: string): void {
    this.lastName = lastName;
  }

  public getCreatedByName(): string {
    return this.createdByName;
  }

  public setCreatedByName(createdByName: string): void {
    this.createdByName = createdByName;
  }

  public getCreatedTimestamp(): string {
    return this.createdTimestamp;
  }

  public setCreatedTimestamp(createdTimestamp: string): void {
    this.createdTimestamp = createdTimestamp;
  }

  public getLanguage(): string {
    return this.language;
  }

  public setLanguage(language: string): void {
    this.language = language;
  }

  public getFMobileNumber(): string {
    return this.mobileNumber;
  }

  public setMobileNumber(mobileNumber: string): void {
    this.mobileNumber = mobileNumber;
  }

  public getSex(): string {
    return this.sex;
  }

  public setSex(sex: string): void {
    this.sex = sex;
  }

  public getPhoto(): string {
    return this.photo;
  }

  public setPhoto(photo: string): void {
    this.photo = photo;
  }

  public getModifiedByName(): string {
    return this.modifiedByName;
  }

  public setMofifiedByName(modifiedByName: string): void {
    this.modifiedByName = modifiedByName;
  }

  public getModifiedTimestamp(): string {
    return this.modifiedTimestamp;
  }

  public setModifiedTimestamp(modifiedTimestamp: string): void {
    this.modifiedTimestamp = modifiedTimestamp;
  }

  public getSectionsCount(): number {
    return this.sectionsCount;
  }

  public setsectionsCount(sectionsCount: number): void {
    this.sectionsCount = sectionsCount;
  }

  public getSectionsId(): number {
    return this.sectionsId;
  }

  public setSectionsId(sectionsId: number): void {
    this.sectionsId = sectionsId;
  }

  public getSectionsNames(): string {
    return this.sectionsNames;
  }

  public setSectionsNames(sectionsNames: string): void {
    this.sectionsNames = sectionsNames;
  }

  public getVillageCountry(): string {
    return this.villageCountry;
  }

  public setVillageCountry(villageCountry: string): void {
    this.villageCountry = villageCountry;
  }

  public getVillageId(): number {
    return this.villageId;
  }

  public setVillageId(villageId: number): void {
    this.villageId = villageId;
  }

  public getVillageFourthLevelZone(): string {
    return this.villageFourthLevelZone;
  }

  public setVillageFourthLevelZone(villageFourthLevelZone: string): void {
    this.villageFourthLevelZone = villageFourthLevelZone;
  }

  public getVillageSecondLevelZone(): string {
    return this.villageSecondLevelZone;
  }

  public setVillageSecondLevelZone(villageSecondLevelZone: string): void {
    this.villageSecondLevelZone = villageSecondLevelZone;
  }

  public getVillageThirdLevelZone(): string {
    return this.villageThirdLevelZone;
  }

  public setVillageThirdLevelZone(villageThirdLevelZone: string): void {
    this.villageThirdLevelZone = villageThirdLevelZone;
  }

  public getVillage(): string {
    return this.village;
  }

  public setVillage(village: string): void {
    this.village = village;
  }

  public getTransactions(): Array<Transaction> {
    return this.transactions;
  }

  public setTransactions(transactions: Array<Transaction>): void {
    this.transactions = transactions;
  }

  public getLatitude(): number {
    return this.latitude;
  }

  public setLatitude(latitude: number): void {
      this.latitude = latitude;
  }

  public getLongitude(): number {
      return this.longitude;
  }

  public setLongitude(longitude: number): void {
      this.longitude = longitude;
  }
}
