import Transaction from "./Transaction";

export default class Producer {
  private data: any;
  private date: string;
  //private logistics: Logistics;
  private netWeight: number;
  private noOfUnits: number;
  private pricePerUnitInBirr: number;
  private productionId: string;
  //private roasterBatches: RoasterBatches;
  private totalPrice: number;

  private transactions: Array<Object>;

  public constructor(data: any /* : ProducerInterface */,transactions: Array<Object>) {
    console.warn(data);
    this.setDate(data.date);
    //this.setLogistics(new Logistics(data.Logistics));
    this.setNoOfUnits(data.NoofUnits);
    this.setPricePerUnitInBirr(data.PricePerUnitInBirr);
    this.setProductionId(data.ProductionID);
    //this.setRoasterBatches(new RoasterBatches(data.RoasterBatches[0]));
    this.setTotalPrice(data.TotalPrice);
    this.setTransactions(transactions);

    this.setNetWeight(this.getTransactions().reduce((old: any, transaction: Transaction) => {
      return old + transaction.getQuantity();
    }, 0));
  }

  /* Getters and setters */

  public getData(): any {
    return this.data;
  }

  public setData(data: any): void {
    this.data = data;
  }

  public getDate(): string {
    return this.date;
  }

  public setDate(date: string): void {
    this.date = date;
  }

  // public getLogistics(): Logistics {
  //     return this.logistics;
  // }

  // public setLogistics(logistics: Logistics): void {
  //     this.logistics = logistics;
  // }

  public getNetWeight(): number {
    return this.netWeight;
  }

  public setNetWeight(netWeight: number): void {
    this.netWeight = netWeight;
  }

  public getNoOfUnits(): number {
    return this.noOfUnits;
  }

  public setNoOfUnits(noOfUnits: number): void {
    this.noOfUnits = noOfUnits;
  }

  public getPricePerUnitInBirr(): number {
    return this.pricePerUnitInBirr;
  }

  public setPricePerUnitInBirr(pricePerUnitInBirr: number): void {
    this.pricePerUnitInBirr = pricePerUnitInBirr;
  }

  public getProductionId(): string {
    return this.productionId;
  }

  public setProductionId(productionId: string): void {
    this.productionId = productionId;
  }

  // public getRoasterBatches(): RoasterBatches {
  //     return this.roasterBatches;
  // }

  // public setRoasterBatches(roasterBatches: RoasterBatches): void {
  //     this.roasterBatches = roasterBatches;
  // }

  public getTotalPrice(): number {
    return this.totalPrice;
  }

  public setTotalPrice(totalPrice: number): void {
    this.totalPrice = totalPrice;
  }

  public getTransactions(): Array<Object> {
    return this.transactions;
  }

  public setTransactions(transactions: Array<Object>): void {
    this.transactions = transactions;
  }
}
