<div class="container-fluid d-lg-none">
  <div class="row footerpages text-center">
    <div class="col">
      <a routerLink="/farmer">
        <div class="rondje" id="farmer">
          <img
            class="grayscale"
            src="/assets/global/images/footer/farmer.png"
            alt="farmer"
          />
        </div>
      </a>
      <span>Farmer</span>
    </div>
    <div class="col">
      <a routerLink="/producer">
        <div class="rondje" id="producer">
          <img
            class="grayscale"
            src="/assets/global/images/footer/producer.png"
            alt="producer"
          />
        </div>
      </a>
      <span>Producer</span>
    </div>
    <div class="col">
      <a routerLink="/brand">
        <div class="rondje" id="brand">
          <img
            class="grayscale"
            src="/assets/global/images/footer/brand.png"
            alt="brand"
          />
        </div>
      </a>
      <span>Brand</span>
    </div>
    <div class="col">
      <a routerLink="/you">
        <div class="rondje" id="you">
          <img
            class="grayscale"
            src="/assets/global/images/footer/you.png"
            alt="you"
          />
        </div>
      </a>
      <span>You</span>
    </div>
  </div>
</div>
