<app-header></app-header>

<div class="container-fluid">
  <div class="row header">
    <agm-map
      [latitude]="52.369000"
      [longitude]="4.899431"
      [zoom]="12"
      [disableDefaultUI]="true"
      id="headerMap"
      [styles]="grayMap"
    >
      <agm-overlay [latitude]="52.385000" [longitude]="4.890000">
        <div>
          <img
            src="/assets/global/images/mapimages/Picture_moyee.png"
            class="headerFarmer pink-background"
          />
        </div>
      </agm-overlay>
    </agm-map>
  </div>

  <div class="row headertext">
    <div class="col-lg-12">
      <div class="bd-title-1">
        <span class="text-black d-lg-inline-block position-relative">
          {{'bd-title-1' | translate}}
        </span>
      </div>

      <div class="bd-paragraph-1">
        <span> {{'bd-paragraph-1' | translate}} </span>
      </div>
    </div>
  </div>

  <div class="row contentrow1">
    <div class="col-lg-6">
      <div>
        <span> {{'bd-storyinfo-1' | translate}} </span>

        <span> {{'bd-storyinfo-2' | translate}} </span>

        <span> {{'bd-storyinfo-3' | translate}} </span>
      </div>

      <div class="text-center">
        <button routerLink="/you" class="btn">
          {{'bd-button-1' | translate}}
        </button>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div>
          <!-- Background -->
        </div>
      </div>

      <div class="row">
        <span class="text-black bd-title-3">
          {{'bd-title-2' | translate}}
        </span>
      </div>

      <div class="row">
        <span class="text-black"> {{'bd-paragraph-2' | translate}} </span>
      </div>

      <div class="row">
        <button
          data-toggle="modal"
          data-target="#kwaliteitscertificaat"
          class="btn"
        >
          {{'bd-button-2' | translate}}
        </button>
      </div>
    </div>

    <!-- Certificate -->
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="kwaliteitscertificaat"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <img
            data-dismiss="modal"
            src="/assets/global/images/SingleQualityCertificate.jpg"
            alt="SingleQualityCertificate"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row contentrow2">
    <div class="col-lg-6">
      <div class="row">
        <span class="text-black"> {{'bd-paragraph-3' | translate}} </span>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <!-- Background -->
      </div>
    </div>
  </div>

  <div class="row footer justify-content-md-center">
    <div class="col col-lg-2">
      <span class="bd-title-3 text-white" style="max-width: 500px">
        {{'bd-title-3' | translate}}
      </span>
    </div>

    <div class="col-lg-3">
      <span class="text-white"> {{'bd-paragraph-4' | translate}} </span>
    </div>

    <div class="col col-lg-2 text-center">
      <p>s</p>
      <button routerLink="/transactions" target="_blank" class="btn">{{'bd-button-3' | translate}}</button>
    </div>
  </div>

  <div class="row spacing d-lg-none back-grey"></div>
</div>

<app-footer></app-footer>
