import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.less'],
})
export class Header implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
