<app-header></app-header>

<div class="container-fluid" *ngIf="getDetailsService().getFarmer()">
  <div class="row header">
    <agm-map
      [latitude]="getDetailsService().getFarmer().getLatitude()"
      [longitude]="getDetailsService().getFarmer().getLongitude()"
      [zoom]="6"
      [disableDefaultUI]="true"
      id="headerMap"
    >
      <agm-overlay [latitude]="getDetailsService().getFarmer().getLatitude()" [longitude]="getDetailsService().getFarmer().getLongitude()">
        <div>
          <img
            src="/assets/global/images/mapimages/farmerIcon.png"
            class="headerIcon"
          />
          <img
            [src]="getDetailsService().getFarmer().getPhoto()"
            class="headerFarmer pink-background"
          />
        </div>
      </agm-overlay>
    </agm-map>
    <div class="mapsOverlay"></div>
  </div>

  <div class="row headertext">
    <div class="col-lg-12">
      <div class="fm-title-1">
        <span class="text-black d-lg-inline-block position-relative">
          {{'fm-title-1' | translate: {FARMER_FULLNAME: getDetailsService().getFarmer().getFullName() | uppercase} }}
          <span class="text-pink"> {{'fm-title-2' | translate }}</span>
        </span>
      </div>

      <div class="fm-paragraph-1">
        <span>
          {{'fm-paragraph-1' | translate: {FARMER_FIRSTNAME: getDetailsService().getFarmer().getFullName()} }}
          <span class="fm-paragraph-2"> {{'fm-paragraph-2' | translate }} </span>
        </span>
      </div>
    </div>
  </div>

  <div class="row contentrow1">
    <div class="col-lg-6">
      <div>
        <span class="text-center"> {{'fm-paragraph-3' | translate}} </span>

        <span class="text-pink"> {{'fm-paragraph-4' | translate}} </span>
      </div>

      <div>
        <span class="text-black"> {{'fm-paragraph-5' | translate}} </span>
      </div>

      <div class="text-center">
        <button routerLink="/producer" class="btn fm-button-1">
          {{'fm-button-1' | translate}}
        </button>
      </div>
    </div>

    <div class="col-lg-6">
      <div>
        <span class="text-black fm-title-3">
          {{'fm-title-3' | translate: {FARMER_FIRSTNAME: getDetailsService().getFarmer().getFullName()} }}
        </span>
      </div>

      <div>
        <agm-map
        [latitude]="getDetailsService().getFarmer().getLatitude()"
        [longitude]="getDetailsService().getFarmer().getLongitude()"
          [zoom]="6"
          [disableDefaultUI]="true"
        >
          <agm-marker
          [latitude]="getDetailsService().getFarmer().getLatitude()"
          [longitude]="getDetailsService().getFarmer().getLongitude()"
            [draggable]="false"
            [iconUrl]="'/assets/global/images/mapimages/SimpleMarker.png'"
          >
          </agm-marker>

          <agm-overlay [latitude]="getDetailsService().getFarmer().getLatitude()" [longitude]="getDetailsService().getFarmer().getLongitude()">
            <div>
              <img [src]="getDetailsService().getFarmer().getPhoto()" class="markerFarmer" />
            </div>
          </agm-overlay>
        </agm-map>
      </div>

      <div>
        <span class="text-black">
          {{'fm-paragraph-6' | translate: {FARMER_FIRSTNAME: getDetailsService().getFarmer().getFullName()} }}
        </span>
      </div>
    </div>
  </div>

  <div class="row contentrow2">
    <div class="col-lg-6">
      <div>
        <span class="text-black fm-title-4">
          {{'fm-title-4' | translate}}
        </span>
      </div>

      <div>
        <span class="text-black"> {{'fm-paragraph-7' | translate}} </span>
      </div>

      <div></div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <span class="text-black fm-title-5">
          {{'fm-title-5' | translate}}
        </span>
      </div>

      <div class="row">
        <span class="text-black">
          {{'fm-paragraph-8' | translate: {FARMER_FIRSTNAME: getDetailsService().getFarmer().getFullName() } }}
        </span>
      </div>

      <div class="row">
        <ul class="ul">
          <img
            class="img-fluid"
            src="/assets/global/images/farmers/timercoffee.png"
            alt="timercoffee"
          />
          <span class="text-grey">
            {{'fm-storyinfo-1' | translate: {FARMER_QUANTITY: getDetailsService().getFarmer().getQuantity() } }}
          </span>
        </ul>

        <ul class="ul">
          <img
            class="img-fluid"
            src="/assets/global/images/farmers/coinscoffee.png"
            alt="coinscoffee"
          />
          <span class="text-grey">
            {{'fm-storyinfo-2' | translate: {FARMER_EARNED: getDetailsService().getFarmer().getEarned() } }}
          </span>
        </ul>

        <ul class="ul">
          <img
            class="img-fluid"
            src="/assets/global/images/farmers/coinscoffee.png"
            alt="coinscoffee"
          />
          <span class="text-grey">
            {{'fm-storyinfo-3' | translate: {FARMER_SUM: getDetailsService().getFarmer().getSum() } }}
          </span>
        </ul>
      </div>

      <div class="row">
        <button
          data-toggle="collapse"
          href="#blockchain1"
          class="btn fm-button-2"
        >
          {{'fm-button-2' | translate}}
        </button>
      </div>

      <div class="row">
        <span class="fm-storyinfo-4 font-weight-light">
          <a routerLink="/transactions">Alle transacties</a> |
          {{'fm-storyinfo-4' | translate}}
        </span>
      </div>

      <div
        class="row table-responsive collapse blockchain-proof"
        id="blockchain1"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Kilo</th>
              <th>Prijs</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of getDetailsService().getFarmer().getTransactions()">
              <td>{{i.sender}}</td>
              <td>{{i.receiver}}</td>
              <td>{{i.getQuantity()}}kg</td>
              <td>€{{i.getPriceInEur()}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row footer justify-content-md-center">
    <div class="col col-lg-2">
      <span class="fm-title-6 text-white" style="max-width: 500px">
        {{'fm-title-6' | translate}}
      </span>
    </div>

    <div class="col-lg-3">
      <span class="text-white"> {{'fm-paragraph-9' | translate}} </span>
    </div>

    <div class="col col-lg-2 text-center d-none d-lg-block">
      <img
        src="/assets/global/images/landingpage/lp-footerlogo.png"
        class="img-fluid"
        alt="footerlogo"
      />
    </div>
  </div>

  <div class="row spacing d-lg-none back-grey"></div>
</div>

<app-footer></app-footer>
