export default class Transaction {
    private txid: string;

    private sender: string;
    private receiver: string;
    private quantity: number;
    private price: number;

    private blockHash: string;
    private blockHeight: number;

    private previousHash: string;
    private timestamp: number;
    private uuid: string;

    public constructor(txid: string, sender: string, receiver: string, quantity: number, price: number, blockHash: string = "", blockHeight: number = 0, previousHash: string = "", timestamp: number = 0, uuid: string = "") {
        this.setTxid(txid);
        this.setSender(sender);
        this.setReceiver(receiver);
        this.setQuantity(quantity);
        this.setPrice(price);
        this.setBlockHash(blockHash);
        this.setBlockHeight(blockHeight);
        this.setPreviousHash(previousHash);
        this.setTimestamp(timestamp);
        this.setUuid(uuid);
    }

    public getPriceInEur(): string {
        const price = (this.getPrice() / 32);
        return price > 0 ? price.toFixed(2) : undefined;
    }

    public getPricePerKg(): string {
        console.log({
            price: this.getPrice(),
            price2: this.getPriceInEur(),
            kg: this.getQuantity()
        })
        const price = parseFloat(this.getPriceInEur()) / this.getQuantity();
        return isNaN(price) ? undefined : price.toFixed(2);
    }

    /**
     * Getters & Setters
     */

    public getTxid(): string {
        return this.txid;
    }

    public setTxid(txid: string): void {
        this.txid = txid;
    }
    
    public getSender(): string {
        return this.sender;
    }

    public setSender(sender: string): void {
        this.sender = sender;
    }

    public getReceiver(): string {
        return this.receiver;
    }

    public setReceiver(receiver: string): void {
        this.receiver = receiver;
    }

    public getQuantity(): number {
        return this.quantity;
    }

    public setQuantity(quantity: number): void {
        this.quantity = quantity;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number): void {
        this.price = price;
    }

    public getBlockHash(): string {
        return this.blockHash;
    }

    public setBlockHash(blockHash: string): void {
        this.blockHash = blockHash;
    }

    public getBlockHeight(): number {
        return this.blockHeight;
    }

    public setBlockHeight(blockHeight: number): void {
        this.blockHeight = blockHeight;
    }

    public getPreviousHash(): string {
        return this.previousHash;
    }

    public setPreviousHash(previousHash: string): void {
        this.previousHash = previousHash;
    }

    public getTimestamp(): number {
        return this.timestamp;
    }

    public setTimestamp(timestamp: number): void {
        this.timestamp = timestamp;
    }

    public getUuid(): string {
        return this.uuid;
    }

    public setUuid(uuid: string): void {
        this.uuid = uuid;
    }

}