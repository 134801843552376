<app-header></app-header>

<div class="container-fluid">
  <div class="row header">
    <agm-map
      [latitude]="52.369000"
      [longitude]="4.899431"
      [zoom]="12"
      [disableDefaultUI]="true"
      id="headerMap"
      [styles]="grayMap"
    >
      <agm-overlay [latitude]="52.385000" [longitude]="4.890000">
        <div>
          <img
            src="/assets/global/images/mapimages/Picture_profile.png"
            class="headerFarmer pink-background"
          />
        </div>
      </agm-overlay>
    </agm-map>
  </div>

  <div class="row headertext">
    <div class="col-lg-12">
      <div class="you-title-1">
        <span class="text-black d-lg-inline-block position-relative">
          {{'you-title-1' | translate}}
        </span>
      </div>

      <div class="you-paragraph-1">
        <span>
          {{'you-paragraph-1' | translate}}
          <span class="you-paragraph-2">
            {{'you-paragraph-2' | translate}}
          </span>
          {{'you-paragraph-3' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row contentrow1">
    <div class="col-lg-6">
      <div class="text-center">
        <img src="/assets/global/images/you/cups.png" alt="cups" />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <span class="text-black you-title-2">
          {{'you-title-2' | translate}}
        </span>
      </div>

      <div class="row">
        <span class="text-black">
          <span class="font-weight-bold"
            >{{'you-paragraph-4' | translate}}</span
          >
          {{'you-paragraph-5' | translate}}
          <span class="font-weight-bold"
            >{{'you-paragraph-6' | translate}}</span
          >
          {{'you-paragraph-7' | translate}}
          <span class="font-weight-bold"
            >{{'you-paragraph-8' | translate}}</span
          >
          {{'you-paragraph-9' | translate}}
        </span>
      </div>

      <div class="row">
        <span class="text-black you-title-3">
          {{'you-title-3' | translate}}
          <span class="text-pink">{{'you-title-4' | translate}}</span>
          {{'you-title-5' | translate}}
        </span>
      </div>

      <div class="row">
        <a target="_blank" href="https://twitter.com/intent/tweet?text=%7BYes, He, ik heb net de totale reis van mijn koffie gechecked en weet nu precies wie er wat verdiend. Amazing transparency. #drinkpink #joinme moyeecoffee.com%7D">
          <img src="/assets/global/images/you/twitter.png" alt="twitter" />
        </a>
        <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://www.moyeecoffee.com/%7B&title=%7BMoyee He, ik heb net de totale reis van mijn koffie gechecked en weet nu precies wie er wat verdiend. Amazing transparency. #drinkpink #joinme moyeecoffee.com">
          <img src="/assets/global/images/you/facebook.png" alt="facebook" />
        </a>
        <a target="_blank" href="http://www.linkedin.com/shareArticle?mini=true&url=https://www.moyeecoffee.com&title=He,%20ik%20heb%20net%20de%20totale%20reis%20van%20mijn%20koffie%20gechecked%20en%20weet%20nu%20precies%20wie%20er%20wat%20verdiend.%20Amazing%20transparency.%20#drinkpink%20#joinme%20moyeecoffee.com">
          <img src="/assets/global/images/you/linkedin.png" alt="linkedin" />
        </a>
        <a target="_blank" href="mailto:wakeup@moyeecoffee.com">
          <img src="/assets/global/images/you/mail.png" alt="mail" />
        </a>
        <a target="_blank" href="whatsapp://send?text=Yes, He, ik heb net de totale reis van mijn koffie gechecked en weet nu precies wie er wat verdiend. Amazing transparency. #drinkpink #joinme moyeecoffee.com">
          <img src="/assets/global/images/you/whatsapp.png" alt="whatsapp" />
        </a>
      </div>
    </div>
  </div>

  <div class="row spacing bg-white d-lg-none"></div>
</div>

<app-footer></app-footer>
